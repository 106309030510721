export const themeDimensions = { colorScheme: 'colorScheme', density: 'density', animation: 'animation' };
export const defaultThemeDimensions = { colorScheme: 'light', density: 'spacious', animation: 'lively' };
export const colorSchemeModes = { light: 'Light', dark: 'Dark' };
export const densityModes = { spacious: 'Spacious', dense: 'Dense' };
export const animationModes = { lively: 'Lively', minimal: 'Minimal' };
export const fileFormats = { json: 'JSON', css: 'CSS', cssVar: 'var(CSS)', scss: 'SCSS', js: 'Javascript', ios: 'iOS', android: 'Android' };
export const prodBaseUrl = 'https://design-tokens.cdn.allianz.com/';
export const tokenTypes = {
	borderRadius: 'borderRadius',
	borderWidth: 'borderWidth',
	color: 'color',
	dimension: 'dimension',
	easing: 'easing',
	fontFamilies: 'fontFamilies',
	fontSizes: 'fontSizes',
	fontWeights: 'fontWeights',
	inset: 'inset',
	letterSpacing: 'letterSpacing',
	lineHeights: 'lineHeights',
	other: 'other',
	scaling: 'scaling',
	sizing: 'sizing',
	spacing: 'spacing',
	textDecoration: 'textDecoration',
	time: 'time',
};
export const typoPreviewText = 'Lorem Ipsum';
